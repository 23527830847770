<div *ngIf="loggedIn">
    <h2>Dashboard</h2>
    <div class="interviewer-alerts" *ngIf="isInterviewer">
        <div *ngIf="overdueLimitReached" class="alert alert-danger">
            <strong>WARNING</strong> &mdash; You currently have overdue interview reports. To try and help you get caught up we have temporarily taken your interviewer profile offline to applicants.<br>
            As you know within the very competitive recruitment environment, we cannot have applications being delayed by late reports. Once you have submitted the missing reports your profile will automatically reactivate.<br>
            Please do contact the office if there is reason for the delay that you would like to discuss, you never know we might be able to help.<br>
            Many thanks.<br>
            CA Team.
        </div>
    </div>
    <div class="info-card top-container" *ngIf="isInterviewerOrAbove">
        <div class="flex-child magenta">
            <app-bookmark-display></app-bookmark-display>
        </div>
        <div class="flex-child green">
        </div>
    </div>
    <div class="status" *ngIf="isInterviewer && selectedInterviewerId">
        <div class="status-components">
            <div class="status-item upcoming info-card" *ngIf="!isOnlineInterviewer">
                <app-interview-ready-interviews [interviewerId]="selectedInterviewerId"></app-interview-ready-interviews>
            </div>
            <div class="status-item upcoming info-card">
                <app-upcoming-interviews [interviewerId]="selectedInterviewerId" [showNextOverhead]="false" [showTime]="true"
                    [compact]="true" [title]="'Upcoming Interviews'"
                    ></app-upcoming-interviews>
            </div>
            <div class="status-item incomplete info-card">
                <app-interview-report-incomplete [interviewerId]="selectedInterviewerId"></app-interview-report-incomplete>
            </div>
            <div class="status-item completed info-card">
                <app-interview-report-rts [interviewerId]="selectedInterviewerId"></app-interview-report-rts>
            </div>
            <!-- This was commented out as a part of CA-3325 - to restore the f2f collumn simply uncomment the code below -->
            <!-- <div class="status-item upcoming info-card" *ngIf="!isOnlineInterviewer">
                <app-f2f-followup-interviews [interviewerId]="selectedInterviewerId"></app-f2f-followup-interviews>
            </div> -->
        </div>
    </div>

</div>
